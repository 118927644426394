div.header{
    margin: 0;
    width: 100%;
    border-bottom: 3px solid #344a94;
    align-items: center;
    display: flex;
}
img.logo{
    margin-left: 10%;
    height: 70px;
}
div.matricula{
    font-size: 10.5px;
    position: absolute;
    right: 155px;
    color: #0000008a;
    font-family: sans-serif;
}
div.exit{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 10.5px;
    color: #0000008a;
    position: absolute;
    right: 90px;
    font-family: sans-serif;
    padding-right: 6px;
}
div.iconExit{
    font-size: 20px;
    color: #757575;
    position: absolute;
    right: 70px;
    vertical-align:middle;
}

