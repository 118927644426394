html,
body {
  height: 100%;
}
.LoginBody {
  background: #344a94 url("../images/dblab_logo_metade.png");
  background-position: right;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.Login {
  padding: 60px 0;
  display: grid;
  place-items: center;
  height: 100vh;

}

.Login form {
  margin: 0 auto;
  max-width: 320px;
}

.Login img {
  padding-bottom: 16px;
}
